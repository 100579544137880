<template>
  <!-- 标准规范管理页 -->
  <div class="standard">
  <Header/>
    <div class="top" v-if="topSearchShow">
      <div class="top_search">
        <input
          type="text"
          @keyup.enter="standSearch"
          class="top_search_inp"
          v-model="standVal"
        />
        <div class="top_search_but" @click="standSearch">搜索</div>
      </div>
    </div>

    <div class="bottom">
      <el-table
        :data="tableData"
        style="width: 100%; margin: 0 auto"
        :row-style="{ height: '40px', cursor: 'pointer' }"
        :header-cell-style="{
          background: '#2BADEF',
          color: '#FFFFFF',
          fontSize: '16px',
          fontWidth: 500,
          textAlign: 'left',
        }"
        @row-click="viewPdf"
      >
        <el-table-column prop="title" label="规范名称" align="left">
        </el-table-column>
<!--        <el-table-column prop="create_time" label="上传时间" align="">-->
<!--        </el-table-column>-->
      </el-table>
<!--      <el-pagination-->
<!--        v-if="currentPageList && tableData.length > 10"-->
<!--        :page-size="standard.page_size"-->
<!--        background-->
<!--        layout="prev, pager, next"-->
<!--        :total="standard.count"-->
<!--        class="page"-->
<!--        prev-text="上一页"-->
<!--        next-text="下一页"-->
<!--        @current-change="MyhandleCurrentChange"-->
<!--      >-->
<!--      </el-pagination>-->
      <el-pagination
        v-if="currentPageSearch && tableData.length > 10"
        :page-size="standard.page_size"
        background
        layout="prev, pager, next"
        :total="standard.count"
        class="page"
        prev-text="上一页"
        next-text="下一页"
        @current-change="MyhandleCurrentChange"
      >
      </el-pagination>
      <el-empty
        v-if="empty"
        :image="require('../assets/images/no_course.png')"
        description="暂无数据"
      ></el-empty>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
export default {
  data() {
    return {
      tableData: [],
      page: 1,
      standard: {},
      topSearchShow: false,
      currentPage: 1, //默认显示第一页
      currentPageList: false, //初始列表页码
      currentPageSearch: false, //搜索后列表页码
      standVal: "",
      page_size: 2,
      empty: false,
    };
  },
  mounted() {
    this.onceSearch();
  },
  methods: {
    MyhandleCurrentChange(page) {
      if (this.$route.query.search) {
        this.handleCurrentChange(page,this.$route.query.search);
      } else {
        this.handleCurrentChangeOne(page,this.$route.query.search);
      }
    },
    onceSearch() {
      if (this.$route.query.search) {
        //如果query中有参数，就执行搜索后的列表
        this.searchStandard(this.$route.query.search);
        // this.topSearchShow = true;
        this.currentPageSearch = true;
      } else {
        this.getStandardData(); //否则就执行原本的列表
        this.currentPageList = true;
      }
    },
    //标准初始列表
    getStandardData() {
      let fd = new FormData();
      fd.append("page", this.page);
      fd.append("page_size", this.page_size);
      this.axios.post("/index/search_list", fd).then((res) => {
        this.tableData = res.data.list;
        this.standard = res.data;
        if (res.data.count == 0) {
          this.empty = true;
        }
      });
    },
    //标准初始更改页码
    handleCurrentChangeOne(page) {
      console.log(page);
      this.currentPage = page;
      let fd = new FormData();
      fd.append("page", page);
      fd.append("page_size", this.page_size);
      this.axios.post("/index/search_list", fd).then((res) => {
        this.tableData = res.data.list;
        this.standard = res.data;
      });
    },
    //标准搜索后列表
    searchStandard(search) {
      let fd = new FormData();
      fd.append("search", search);
      fd.append("page", this.page);
      this.axios.post("/index/search_list", fd).then((res) => {
        this.standard = res.data;
        this.tableData = res.data.list;
      });
    },
    //标准搜索后的更改页码
    handleCurrentChange(page,search) {
      this.currentPage = page;
      let fd = new FormData();
      fd.append("page", page);
      fd.append("search", search);
      fd.append("page_size", this.page_size);
      this.axios.post("/index/search_list", fd).then((res) => {
        this.standard = res.data;
        this.tableData = res.data.list;
      });
    },
    //标准搜索大按钮
    standSearch() {
      this.$router.push(`/standard?search=${this.standVal}`);
      this.$router.go(0);
    },
    viewPdf(row) {
      if(row.type == '1'){
        this.$router.push(`/pdfviewer?pu=${row.url}`);
      }else{
        this.$router.push(`/reviewNewsContent?id=${row.id}`);
      }
    },
  },
  components: { Header},
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.standVal = vm.$route.query.search;
    });
  },
};
</script>

<style lang="scss">
// 去掉多余的暂无数据
.el-table__empty-block {
  display: none;
}
/* el-table表格去掉横线 */
.bottom {
  .el-table__row > td {
    border: none;
  }
  .el-table::before {
    height: 0px;
  }
  .el-table__body {
    width: 100%;
  }
  .el-table__cell div {
    margin-left: 50px;
  }
}
.el-table th.el-table__cell > .cell {
  padding-left: 392px;
}
//分页的公共样式
.el-pagination {
  text-align: center;
  margin-top: 30px;
  .btn-prev,
  .btn-next {
    background-color: #fff !important;
    border: 1px solid #66a4ff;
    border-radius: 3px !important;
    padding: 5px 10px !important;
    box-sizing: border-box !important;
    &:hover {
      color: #66a4ff;
    }
    span {
      line-height: 1;
    }
  }
  .el-pager {
    li {
      font-weight: normal;
    }
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 1378px) {
  .standard {
    min-height: 140vh;
  }
}
@media screen and (max-width: 1555px) and (min-width: 1380px) {
  .standard {
    min-height: 120vh;
  }
}
@media screen and (min-width: 1555px) {
  .standard {
    min-height: 100vh;
  }
}
.standard {
  // min-height: calc(100vh - 80px);
  // min-height: 140vh;
  width: 100%;
  background: #f8f8f8;
  padding-top: 1px;
  overflow: hidden;
  .top {
    width: 100%;
    height: 200px;
    background: #eaeefc;
    display: flex;
    justify-content: center;
    align-items: center;
    .top_search {
      width: 35%;
      height: 59px;
      background: #fff;
      display: flex;
      justify-content: flex-end;
      .top_search_inp {
        width: 100%;
        outline: none;
        border: 0 solid;
        font-size: 26px;
        font-weight: 200;
          padding-left: 20px;
      }
      .top_search_but {
        width: 22%;
        height: 100%;
        line-height: 350%;
        background: #83b1fc;
        font-size: 16px;
        text-align: center;
        color: #ffffff;
      }
    }
  }
  // @media screen and (max-width: 1920px) {
  .bottom {
    width: 50%;
    height: 100%;
    margin: 0 auto;
    margin-top: 20px;
    background: #fff;
    padding-bottom: 20px;
    margin-bottom: 15px;
  }
  .page {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 10px;
  }
  //   }
  // @media screen and (max-width: 1555px) and (min-width: 1280px) {
  //       .bottom {
  //         width: 70%;
  //         height: 600px;
  //         margin: 0 auto;
  //         margin-top: 30px;
  //         background: #fff;
  //   }
  //     .page {
  //       text-align: center;
  //       margin-top: 40px;
  //       margin-bottom: 10px;
  //     }
  //   }
  //     @media screen and (max-width: 1280px) {
  //       .bottom {
  //         width: 100%;
  //         height: 600px;
  //         margin: 0 auto;
  //         margin-top: 30px;
  //         background: #fff;
  //      }
  //      .page {
  //       text-align: center;
  //       margin-top: 40px;
  //       margin-bottom: 10px;
  //     }
  //   }
}
</style>
